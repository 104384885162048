import React, { useState, useEffect, useContext } from "react";
import styles from "./NavBar.module.css";
import { ACTIONS, ClientContext } from "../../ClientProvider";
import { Link as LinkRouter } from "react-router-dom";
import {
	AppBar,
	Drawer,
	Toolbar,
	IconButton,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Divider
} from "@material-ui/core";
import {
	Menu as MenuIcon,
	ContactSupport as SupportIcon,
	Dashboard as DashboardIcon,
	ExitToApp as ExitIcon,
	AccountCircle as AccountCircleIcon
} from "@material-ui/icons";
import {
	ButtonExternalLink,
	ButtonInternalLink,
	ExternalLink,
	InternalLink
} from "./common/Link";

export default function NavBar() {
	const [state, dispatch] = useContext(ClientContext);
	const [drawerState, setDrawerState] = useState(false);
	const openDrawer = () => setDrawerState(true);
	const closeDrawer = () => setDrawerState(false);

	// autoclose on resize
	useEffect(() => {
		window.addEventListener("resize", closeDrawer);
		return () => {
			window.removeEventListener("resize", closeDrawer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logout = () => {
		dispatch({ type: ACTIONS.LOGOUT });
	};

	return (
		<>
			<AppBar position="fixed" color={"secondary"}>
				<Toolbar className={styles.toolbar}>
					<LinkRouter
						to="/"
						className={styles.titleWrapper}
						aria-label="home"
						role="menuitem"
						tabIndex="0"
					>
						<div
							className={styles.logo}
							style={{
								backgroundImage: `url('${process.env.PUBLIC_URL}/res/icons/avatar_nobg.svg'), linear-gradient(to bottom right, #ffc222, #e75301)`
							}}
						></div>
					</LinkRouter>
					<Typography variant="h6" className={styles.title}>
					<LinkRouter
						to="/"
						aria-label="home"
						tabIndex="0"
					>
						Member Counter
					</LinkRouter>
					</Typography>
					<IconButton
						edge="end"
						className={styles.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={openDrawer}
					>
						<MenuIcon />
					</IconButton>
					{/* Desktop */}
					<div className={styles.navEntries}>
						<ButtonExternalLink
							color="inherit"
							href={process.env.REACT_APP_SUPPORT_SERVER}
						>
							Support
						</ButtonExternalLink>
						<ButtonInternalLink color="inherit" to="/dashboard">
							Dashboard
						</ButtonInternalLink>
						{state.client.isLoggedIn && (
							<ButtonInternalLink color="inherit" to="/account">
								Account
							</ButtonInternalLink>
						)}
					</div>
				</Toolbar>
				<Drawer
					anchor="right"
					open={drawerState}
					onClose={closeDrawer}
					onClick={closeDrawer}
				>
					{/* Mobile */}
					<List className={styles.list}>
						<ListItem
							button
							key={"support"}
							component={ExternalLink}
							color="inherit"
							href={process.env.REACT_APP_SUPPORT_SERVER}
						>
							<ListItemIcon>
								<SupportIcon />
							</ListItemIcon>
							<ListItemText primary={"Support"} />
						</ListItem>
						<ListItem
							button
							key={"dashboard"}
							component={InternalLink}
							color="inherit"
							to="/dashboard"
						>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText primary={"Dashboard"} />
						</ListItem>
						{state.client.isLoggedIn ? (
							<>
								<ListItem
									button
									key={"account"}
									component={InternalLink}
									color="inherit"
									to="/account"
								>
									<ListItemIcon>
										<AccountCircleIcon />
									</ListItemIcon>
									<ListItemText primary={"Account"} />
								</ListItem>
								<Divider />
								<ListItem
									button
									key={"logout"}
									onClick={logout}
									component={InternalLink}
									color="inherit"
									to="/"
								>
									<ListItemIcon>
										<ExitIcon />
									</ListItemIcon>
									<ListItemText primary={"Logout"} />
								</ListItem>
							</>
						) : (
							<>
								<Divider />

								<ListItem
									button
									key={"login"}
									component={InternalLink}
									color="inherit"
									to="/login"
								>
									<ListItemIcon>
										<AccountCircleIcon />
									</ListItemIcon>
									<ListItemText primary={"Login"} />
								</ListItem>
							</>
						)}
					</List>
				</Drawer>
			</AppBar>
			<Toolbar />
		</>
	);
}

import React, { useState } from "react";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { ButtonInternalLink } from "./common/Link";
import styles from "./CookieBanner.module.css";

const currentCookieVer = 3;

const CookieBanner = () => {
	const [cookieAgreement, setCookieAgreement] = useState(
		Number(localStorage.getItem("cookies-agreement"))
	);

	const gotIt = () => {
		setCookieAgreement(currentCookieVer);
		localStorage.setItem("cookies-agreement", currentCookieVer);
	};

	if (cookieAgreement >= currentCookieVer) return null;

	return (
		<>
			<Card className={styles.root} variant="elevation" elevation={"8"}>
				<CardContent>
					We use first and third-party essential cookies to help improve your
					experience of this website.
				</CardContent>
				<CardActions className={styles.actions}>
					<ButtonInternalLink to="/legal/cookie-policy">
						Learn more
					</ButtonInternalLink>
					<Button onClick={gotIt} color="primary" variant="contained">
						Got it
					</Button>
				</CardActions>
			</Card>
		</>
	);
};

export default CookieBanner;

import { Button, Typography } from "@material-ui/core";
import React from "react";
import Footer from "../../components/Footer";
import Demo from "./Demo";

import styles from "./index.module.css";

const Homepage = () => {
	return (
		<div className={styles.root}>
			<article className={styles.presentation}>
				<Typography variant="h4" component="h1">
					Member Counter
				</Typography>
				<Typography variant="h6" component="h2">
					A discord bot that counts users, online users, users with a role, or
					whatever you want in a channel name or in a channel topic.
					<br />
					Try it for free and explore endless possibilities, the only limit is
					your imagination.
				</Typography>
				<div className={styles.presentationButtons}>
					<Button
						color="primary"
						variant="contained"
						fullWidth
						href={process.env.REACT_APP_BOT_INVITE_LINK}
						target="_blank"
						rel="noreferrer"
					>
						Add to Discord
					</Button>
					<Button
						color="primary"
						variant="contained"
						fullWidth
						href={process.env.REACT_APP_COUNTER_LIST_LINK}
						target="_blank"
						rel="noreferrer"
					>
						Counter list
					</Button>
				</div>
			</article>
			<article className={styles.demo}>
				<Demo />
			</article>
			<Footer />
		</div>
	);
};

export default Homepage;

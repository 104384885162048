import {
	Card,
	CardContent,
	Container,
	Tab,
	Tabs,
	Divider
} from "@material-ui/core";
import React, { useEffect } from "react";
import {
	Route,
	Switch,
	Redirect,
	useRouteMatch,
	useHistory
} from "react-router-dom";
import styles from "./index.module.css";
import Page from "../../components/common/Pages";
import PrivacyPolicy from "./PrivacyPolicy";
import ToS from "./ToS";
import CookiePolicy from "./CookiePolicy";
import AcceptableUsePolicy from "./AcceptableUsePolicy";
import Footer from "../../components/Footer";
import { ButtonInternalLink } from "../../components/common/Link";

const pages = [
	{ path: "terms-of-service", name: "Terms Of Service", component: <ToS /> },
	{
		path: "privacy-policy",
		name: "Privacy Policy",
		component: <PrivacyPolicy />
	},
	{ path: "cookie-policy", name: "Cookie Policy", component: <CookiePolicy /> },
	{
		path: "acceptable-use-policy",
		name: "Acceptable Use Policy",
		component: <AcceptableUsePolicy />
	}
];

const LegalPage = () => {
	const history = useHistory();
	const { path } = useRouteMatch();
	const routePolicyMatch = useRouteMatch(`${path}/:policy`);

	const policyMatch = pages.findIndex(
		(page) => page.path === routePolicyMatch?.params.policy
	);

	console.log(routePolicyMatch);

	const [selectedPage, setSelectedPage] = React.useState(
		policyMatch > -1 ? policyMatch : 0
	);

	useEffect(() => {
		setSelectedPage(policyMatch > -1 ? policyMatch : 0);
	}, [policyMatch]);

	const handleChange = (event, newValue) => {
		setSelectedPage(newValue);
		history.push(`${path}/${pages[newValue].path}`);
	};

	return (
		<div className={styles.root}>
			<Container maxWidth="md">
				<h1>Legal</h1>
				<Card>
					<Tabs
						variant="fullWidth"
						value={selectedPage}
						indicatorColor="primary"
						textColor="primary"
						onChange={handleChange}
						aria-label="legal pages"
					>
						{pages.map(({ path: pagePath, name }) => (
							<Tab
								key={pagePath}
								label={name}
								component={ButtonInternalLink}
								to={`${path}/${pagePath}`}
							/>
						))}
					</Tabs>
					<Divider />
					<CardContent style={{ paddingTop: 0 }}>
						<Switch>
							<Route path={path} exact>
								<Redirect to={`${path}/terms-of-service`} />
							</Route>
							{pages.map(({ path: pagePath, name, component }) => (
								<Route key={pagePath} path={`${path}/${pagePath}`}>
									<Page title={name}>{component}</Page>
								</Route>
							))}
							<Route path="*">
								<Redirect to="/404" />
							</Route>
						</Switch>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<ButtonInternalLink to="/" variant="contained" color="primary">
								Go home
							</ButtonInternalLink>
						</div>
					</CardContent>
				</Card>
			</Container>
			<div style={{ padding: "10px" }} />
			<Footer />
		</div>
	);
};

export default LegalPage;

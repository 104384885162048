import React from "react";
import { Link as MuiLink, Button } from "@material-ui/core";
import { Link as LinkRouter } from "react-router-dom";

export const InternalLink = (props) => {
	return (
		<MuiLink rel="noreferrer" component={LinkRouter} {...props}>
			{props.children}
		</MuiLink>
	);
};

export const ExternalLink = (props) => {
	return (
		<MuiLink
			target="_blank"
			rel="noreferrer"
			{...props}
			href={props.to ?? props.href}
		>
			{props.children}
		</MuiLink>
	);
};

export const ButtonExternalLink = (props) => {
	return (
		<Button
			target="_blank"
			rel="noreferrer"
			{...props}
			href={props.to ?? props.href}
		>
			{props.children}
		</Button>
	);
};

export const ButtonInternalLink = (props) => {
	return (
		<Button
			rel="noreferrer"
			component={LinkRouter}
			{...props}
			to={props.to ?? props.href}
		>
			{props.children}
		</Button>
	);
};

import { createTheme } from "@material-ui/core";

const palette = {
	type: "dark",
	primary: {
		light: "#ffb449",
		main: "#f28410",
		dark: "#b95600",
		contrastText: "#000"
	},
	secondary: {
		light: "#4e4e4e",
		main: "#262626",
		dark: "#000000",
		contrastText: "#fff"
	}
};

const theme = createTheme({
	palette,
	props: {
		MuiCard: {
			variant: "outlined"
		},
		MuiTooltip: {
			arrow: true
		}
	},
	overrides: {
		MuiTooltip: {
			arrow: {
				color: "#000"
			},
			tooltip: {
				backgroundColor: "#000",
				fontSize: "0.9rem",
				lineHeight: "1.2rem",
				padding: "10px",
				boxShadow: "black 0 3px 10px -3px"
			}
		}
	}
});

export default theme;

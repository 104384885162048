import React, { Suspense } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import theme from "./App/theme";

import Loading from "./App/components/common/Loading";

import NavBar from "./App/components/NavBar";
import Homepage from "./App/pages/homepage";
import Login from "./App/pages/login";
import NotFoundPage from "./App/pages/NotFoundPage";

import ClientContext from "./ClientProvider";
import Page from "./App/components/common/Pages";
import LegalPage from "./App/pages/legal";
import ScrollTop from "./App/components/common/ScrollTop";
import CookieBanner from "./App/components/CookieBanner";

const Utils = React.lazy(() => import("./App/pages/utils/all"));
const Dashboard = React.lazy(() => import("./App/pages/dashboard"));
const Account = React.lazy(() => import("./App/pages/account"));

const App = (props) => {
	return (
		<PayPalScriptProvider
			options={{
				"client-id": process.env.REACT_APP_PAYPAL_ID,
				currency: "EUR",
				vault: true,
				intent: "subscription"
			}}
		>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
			>
				<ClientContext>
					<div className={"app"}>
						<ThemeProvider theme={theme}>
							<Router basename={process.env.PUBLIC_URL}>
								<ScrollTop />
								<CookieBanner />
								<NavBar />
								<div className={"page"}>
									<Switch>
										<Route path="/" exact>
											<Homepage />
										</Route>
										<Route path="/utils">
											<Suspense fallback={Loading}>
												<Page title="Utilities">
													<Utils />
												</Page>
											</Suspense>
										</Route>
										<Route
											path="/docs"
											component={() => {
												window.location.href = process.env.REACT_APP_BOT_DOCS;
												return null;
											}}
										/>
										<Route path="/login" component={Login} />
										<Route path="/dashboard">
											<Suspense fallback={Loading}>
												<Page title="Dashboard">
													<Dashboard />
												</Page>
											</Suspense>
										</Route>
										<Route path="/account">
											<Suspense fallback={Loading}>
												<Page title="Account">
													<Account />
												</Page>
											</Suspense>
										</Route>
										<Route
											path="/support"
											component={() => {
												window.location.href =
													process.env.REACT_APP_SUPPORT_SERVER;
												return null;
											}}
										/>
										<Route
											path="/invite"
											component={() => {
												window.location.href =
													process.env.REACT_APP_BOT_INVITE_LINK;
												return null;
											}}
										/>
										<Route path="/legal">
											<Suspense fallback={Loading}>
												<LegalPage />
											</Suspense>
										</Route>
										<Route path="/premium">
											<Redirect to="/dashboard/bots"></Redirect>
										</Route>
										<Route path="/donate">
											<Redirect to="/dashboard/bots"></Redirect>
										</Route>
										<Route path="*">
											<Page title="404 Not found">
												<NotFoundPage />
											</Page>
										</Route>
									</Switch>
								</div>
							</Router>
						</ThemeProvider>
					</div>
				</ClientContext>
			</SnackbarProvider>
		</PayPalScriptProvider>
	);
};

export default App;

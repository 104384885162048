import React from "react";
import { InternalLink } from "../../components/common/Link";
import Footer from "../../components/Footer";
import styles from "./index.module.css";

const NotFoundPage = () => {
	return (
		<div className={styles.root}>
			<div style={{ flexGrow: 1 }}></div>
			<h1>404</h1>
			<h2>Page not found</h2>
			<InternalLink to="/">Go home</InternalLink>
			<div style={{ flexGrow: 1 }}></div>
			<Footer></Footer>
		</div>
	);
};

export default NotFoundPage;

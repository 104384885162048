import { useState } from "react";

// https://medium.com/@patrick.gross1987/how-to-use-the-react-context-api-with-an-asynchronous-reducer-5651c2dc26aa
const useAsyncReducer = (reducer, initialState = null) => {
	const [state, setState] = useState(initialState);

	const dispatch = async (action) => {
		const result = reducer(state, action);
		if (typeof result.then === "function") {
			const newState = await result;
			setState(newState);
		} else {
			setState(result);
		}
	};

	return [state, dispatch];
};

export default useAsyncReducer;

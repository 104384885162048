import React, { createContext } from "react";
import client from "./client";
import useAsyncReducer from "./hooks/useAsyncReducer";

export const ClientContext = createContext();

export const ACTIONS = {
	// Auth
	LOGIN: "LOGIN",
	LOGOUT: "LOGOUT",

	// User
	FETCH_ME: "FETCH_ME",
	FETCH_MY_BOTS: "FETCH_MY_BOTS",
	FETCH_MY_GUILDS: "FETCH_MY_GUILDS",
	FETCH_USER: "GET_USER",
	MODIFY_USER: "MODIFY_USER",
	DELETE_USER: "DELETE_USER",
	FETCH_USER_BOTS: "FETCH_USER_BOTS",

	// Bots
	FETCH_BOT: "FETCH_BOT",
	CREATE_BOT: "CREATE_BOT",
	MODIFY_BOT: "MODIFY_BOT",
	DELETE_BOT: "DELETE_BOT",
	FETCH_BOT_STATUS: "FETCH_BOT_STATUS",
	START_BOT: "START_BOT",
	STOP_BOT: "STOP_BOT",
	SET_BOT_SUBSCRIPTION: "SET_BOT_SUBSCRIPTION",
	FETCH_BOT_SUBSCRIPTION: "FETCH_BOT_SUBSCRIPTION",
	PAUSE_BOT_SUBSCRIPTION: "PAUSE_BOT_SUBSCRIPTION",
	RESUME_BOT_SUBSCRIPTION: "RESUME_BOT_SUBSCRIPTION",
	DELETE_BOT_SUBSCRIPTION: "DELETE_BOT_SUBSCRIPTION"
};

const initialState = {
	client,
	me: null,
	users: new Map(),
	bots: new Map(),
	guilds: new Map()
};

const reducer = async (state, payload = {}) => {
	switch (payload.type) {
		// Auth
		case ACTIONS.LOGIN:
			await state.client.login(payload.code);
			break;
		case ACTIONS.LOGOUT:
			state.client.logout();

			state.me = null;
			state.users = new Map();
			state.bots = new Map();
			state.guilds = new Map();

			break;

		// User
		case ACTIONS.FETCH_ME: {
			const user = await state.client.getMe();
			user.discord = await state.client.getMyDiscord();
			state.me = user.id;
			state.users.set(user.id, user);
			break;
		}
		case ACTIONS.FETCH_MY_BOTS: {
			const bots = await Promise.all(
				(await state.client.getMyBots()).map(async (botId) => {
					const bot = await state.client.getBot(botId);
					const oldBot = state.bots.get(botId);

					if (bot.config?.token) {
						if (bot.config?.token !== oldBot?.config?.token) {
							await state.client
								.getBotDiscordProfile(botId)
								.then((discordBot) => {
									if (discordBot) bot.discord = discordBot;
								})
								.catch(() => {
									delete bot?.discord;
								});
						} else {
							bot.discord = oldBot?.discord;
						}
					} else if (!bot.config?.token?.length) {
						delete bot?.discord;
					}

					return bot;
				})
			);

			bots.forEach((bot) => state.bots.set(bot.id, bot));
			break;
		}

		case ACTIONS.FETCH_MY_GUILDS: {
			const guilds = await state.client.getMyGuilds();
			guilds.forEach((guild) => state.guilds.set(guild.id, guild));
			break;
		}

		case ACTIONS.FETCH_USER: {
			const user = await state.client.getUser(payload.id);
			state.users.set(payload.id, user);
			break;
		}
		case ACTIONS.MODIFY_USER: {
			const user = await state.client.modifyUser(payload.id, payload.user);
			state.users.set(payload.id, user);
			break;
		}
		case ACTIONS.DELETE_USER: {
			await state.client.deleteUser(payload.id);
			state.users.delete(payload.id);
			break;
		}
		case ACTIONS.FETCH_USER_BOTS: {
			const bots = await Promise.all(
				(await state.client.getUserBots(payload.id)).map(async (botId) => {
					const bot = await state.client.getBot(botId);
					const oldBot = state.bots.get(botId);

					if (bot.config?.token) {
						if (bot.config?.token !== oldBot?.config?.token) {
							await state.client
								.getBotDiscordProfile(botId)
								.then((discordBot) => {
									if (discordBot) bot.discord = discordBot;
								})
								.catch(() => {
									delete bot?.discord;
								});
						} else {
							bot.discord = oldBot?.discord;
						}
					} else if (!bot.config?.token?.length) {
						delete bot?.discord;
					}

					return bot;
				})
			);

			bots.forEach((bot) => state.bots.set(bot.id, bot));
			break;
		}

		// Bots
		case ACTIONS.FETCH_BOT: {
			const newData = await state.client.getBot(payload.id);
			const bot = state.bots.get(payload.id);

			if (newData.config?.token) {
				if (newData.config?.token !== bot?.config?.token) {
					await state.client
						.getBotDiscordProfile(payload.id)
						.then((discordBot) => {
							if (discordBot) newData.discord = discordBot;
						})
						.catch(() => {
							newData.discord = null;
						});
				} else {
					newData.discord = bot?.discord;
				}
			} else if (!newData.config?.token?.length) {
				newData.discord = null;
			}

			if (bot) Object.assign(bot, newData);
			break;
		}
		case ACTIONS.CREATE_BOT: {
			const bot = await state.client.createBot();
			state.bots.set(payload.id, bot);
			break;
		}
		case ACTIONS.MODIFY_BOT: {
			await state.client.modifyBot(payload.id, payload.bot);
			break;
		}
		case ACTIONS.DELETE_BOT: {
			await state.client.deleteBot(payload.id);
			state.bots.delete(payload.id);
			break;
		}
		case ACTIONS.FETCH_BOT_STATUS: {
			if (!state.bots.has(payload.id))
				throw new Error("You must fetch the bot first");
			const bot = state.bots.get(payload.id);
			bot.status = await state.client.getBotStatus(payload.id);
			break;
		}
		case ACTIONS.START_BOT: {
			await state.client.startBot(payload.id);
			break;
		}
		case ACTIONS.STOP_BOT: {
			await state.client.stopBot(payload.id);
			break;
		}
		case ACTIONS.SET_BOT_SUBSCRIPTION: {
			if (!state.bots.has(payload.id))
				throw new Error("You must fetch the bot first");
			const bot = state.bots.get(payload.id);
			await state.client.setBotSubscription(
				payload.id,
				payload.subscriptionId,
				payload.quota
			);
			const subscription =
				payload.subscriptionId !== "credits"
					? await state.client.getBotSubscription(payload.id)
					: undefined;

			bot.subscriptionId = payload.subscriptionId;
			bot.subscription = subscription;
			break;
		}
		case ACTIONS.FETCH_BOT_SUBSCRIPTION: {
			if (!state.bots.has(payload.id))
				throw new Error("You must fetch the bot first");
			const bot = state.bots.get(payload.id);
			const subscription = await state.client.getBotSubscription(payload.id);

			bot.subscription = subscription;
			break;
		}
		case ACTIONS.PAUSE_BOT_SUBSCRIPTION: {
			await state.client.pauseBotSubscription(payload.id);
			const bot = state.bots.get(payload.id);
			const subscription = await state.client.getBotSubscription(payload.id);

			bot.subscription = subscription;
			break;
		}
		case ACTIONS.RESUME_BOT_SUBSCRIPTION: {
			await state.client.resumeBotSubscription(payload.id);
			const bot = state.bots.get(payload.id);
			const subscription = await state.client.getBotSubscription(payload.id);

			bot.subscription = subscription;
			break;
		}
		case ACTIONS.DELETE_BOT_SUBSCRIPTION: {
			await state.client.deleteBotSubscription(payload.id);
			const bot = state.bots.get(payload.id);

			delete bot?.subscription;
			delete bot?.subscriptionId;
			break;
		}
		default:
			break;
	}

	return { ...state };
};

const ClientProvider = ({ children }) => {
	const [state, dispatch] = useAsyncReducer(reducer, initialState);
	return (
		<ClientContext.Provider value={[state, dispatch]}>
			{children}
		</ClientContext.Provider>
	);
};

export default ClientProvider;

import React, { useContext, useEffect } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { ACTIONS, ClientContext } from "../../../ClientProvider";
import Loading from "../../components/common/Loading";
import { useSnackbar } from "notistack";

const Login = (props) => {
	const [state, dispatch] = useContext(ClientContext);
	const history = useHistory();
	const location = useLocation();
	const { enqueueSnackbar } = useSnackbar();

	const { code } = queryString.parse(location.search);

	useEffect(() => {
		if (code) {
			dispatch({ type: ACTIONS.LOGIN, code })
				.then(() => {
					const redirectAfterLogin =
						sessionStorage.getItem("redirectAfterLogin") ?? "/";
					sessionStorage.removeItem("redirectAfterLogin");
					history.push(redirectAfterLogin);
				})
				.catch((error) => {
					history.push("/");
					enqueueSnackbar(error.message, { variant: "error" });
				});
		} else {
			state.client.getOAuth2Screen().then((url) => {
				window.location.href = url;
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]);

	return (
		<>
			<Loading />
		</>
	);
};

export default Login;

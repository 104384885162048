import React from "react";
import styles from "./Loading.module.css";
import { CircularProgress } from "@material-ui/core";

const Loading = (props) => {
	return (
		<div {...props} className={styles.root}>
			<CircularProgress />
		</div>
	);
};
export default Loading;

import React, { useEffect, useState } from "react";
import DSelector from "../../components/common/DSelector";
import styles from "./Demo.module.css";
import {
	Lock as LockIcon,
	HelpOutline,
	LibraryAddCheck as LibraryAddCheckIcon,
	ScreenRotation as ScreenRotationIcon,
	AspectRatio as AspectRatioIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon
} from "@material-ui/icons";
import {
	Button,
	IconButton,
	SvgIcon,
	Tooltip,
	Typography
} from "@material-ui/core";
import TagIcon from "./TagIcon";
import CampaignIcon from "./CampaignIcon";
import random from "../../utils/random";
import classNames from "classnames";

const ChannelIcons = {
	voice: <LockIcon />,
	text: (
		<SvgIcon>
			<TagIcon />
		</SvgIcon>
	),
	rules: <LibraryAddCheckIcon />,
	announcement: (
		<SvgIcon>
			<CampaignIcon />
		</SvgIcon>
	),
	category: <KeyboardArrowDownIcon />
};

const TextBased = ["text", "rules", "announcement"];

const demoServers = [
	{
		name: "Member Counter",
		image:
			"https://media.discordapp.net/attachments/912366555713667072/912367865632534578/a_33cf18af873f3bede980af9650e99f1a.png?size=96",
		counters: [
			{
				type: "voice",
				name: "616 Members"
			},
			{
				type: "voice",
				name: "379 left to reach 1000!"
			},
			{
				type: "category",
				name: "6.3M USERS - 12K SERVERS!",
				isCounter: true
			},

			{
				type: "rules",
				name: "welcome"
			},
			{
				type: "announcement",
				name: "announcements"
			},
			{
				type: "category",
				name: "📝chat"
			},
			{
				initActive: true,
				type: "text",
				name: "general",
				topic: "Casual Chat - 320 Online"
			},
			{
				initActive: true,
				type: "text",
				name: "off-topic",
				topic: "There are 79 cat pics in eduardozgz.com/max 👀"
			},
			{
				type: "text",
				name: "support",
				topic: "16 volunteers are ready to help you"
			}
		],
		description:
			"This is the official Member Counter Support server, feel free to join and ask stuff in the right channels. We use counters here to see the members of this server and how many users and servers have the official bots.",
		invite: "https://discord.gg/g4MfV6N"
	},
	{
		name: "Hikari Support Server",
		image:
			"https://media.discordapp.net/attachments/912366555713667072/912368842179772488/fb670f0539386769d23b438b02fdaea7.png?size=96",
		counters: [
			{
				type: "category",
				name: "📊 Bot Stats 📊"
			},
			{
				type: "voice",
				name: "5.8K users"
			},
			{
				type: "voice",
				name: "68 servers"
			},
			{
				type: "category",
				name: "INFORMATION"
			},
			{
				type: "text",
				name: "welcome"
			},
			{
				type: "text",
				name: "server-information"
			},
			{
				initActive: true,
				type: "rules",
				name: "📜 server-rules 📜",
				topic: "36 Have accepted the rules"
			},
			{
				type: "announcement",
				name: "server-announcements"
			},
			{
				type: "category",
				name: "GENERAL"
			},
			{
				type: "text",
				name: "talking",
				topic: "82 Online - Remember to read our rules!"
			},
			{
				type: "text",
				name: "memes",
				topic: "29 Memeing"
			}
		],
		description:
			"This is the official Hikari Support server. Hikari is a free music and fun discord bot. They mainly use Member Counter to keep track of the total users and servers the bot is serving.",
		invite: "https://discord.gg/ReKmuSq7WT",
		inviteBot:
			"https://discord.com/api/oauth2/authorize?client_id=702638497860812901&permissions=292940912&scope=bot",
		inviteBotText: "Add Hikari to Discord"
	},
	{
		name: "Ah yes.. Among us..",
		image:
			"https://media.discordapp.net/attachments/912366555713667072/912367243524980796/a_d9783375513bdf5f5c5c237b8fa1a5ca.gif?size=96",
		counters: [
			{
				type: "voice",
				name: "☁️ Hà Nội│Hải Phòng 🌥"
			},
			{
				type: "voice",
				name: "25° - 33° 🌡️ 25° - 32°"
			},
			{
				type: "voice",
				name: "100 💧 94 │ 5% 🌧️ 0%"
			},
			{
				type: "category",
				name: "CHAT AND CHIT"
			},
			{
				type: "text",
				name: "🎨│role-settings"
			},
			{
				type: "text",
				name: "📆│schedule"
			},
			{
				type: "text",
				name: "💬│chat",
				initActive: true
			},
			{
				type: "text",
				name: "💾│among-mod"
			}
		],
		description: (
			<>
				A server for a group of close friends since the day Among Us broke the
				internet. We do gaming, watch movies together and all other fun things.
				They use the <code>{"{http}"}</code> counter to display data from
				OpenWeatherMap&apos;s free API. All data like min/max temperature of the
				day, humidity, chance of rain, are updated every 30 minutes to avoid
				hitting the API&apos;s rate limits. They also used the{" "}
				<code>{"{replace}"}</code> counter to convert OpenWeatherMap&apos;s icon
				codes to emojis.
			</>
		)
	},
	{
		name: "Pencıl",
		image: "https://media.discordapp.net/attachments/912366555713667072/912366979946528818/CrhDMN4.gif?size=96",
		counters: [
			{
				type: "voice",
				name: "89 Üye"
			},
			{
				type: "voice",
				name: "27 Aktif Üye"
			},
			{
				type: "category",
				name: "[📖] Önemli"
			},
			{
				type: "rules",
				name: "kurallar",
				topic: "📕 74 Üye Kuralları Kabul Etti"
			},
			{
				type: "announcement",
				name: "anonslar"
			},
			{
				type: "category",
				name: "[📋] Sunucu"
			},
			{
				type: "text",
				name: "giriş-çıkış"
			},
			{
				type: "category",
				name: "[💬] Sohbet"
			},
			{
				initActive: true,
				type: "text",
				name: "genel-sohbet",
				topic: "27 Aktif Üye - Lütfen kuralları okumayı unutmayın!"
			},
			{
				type: "text",
				name: "dosya-paylaşım"
			}
		],
		description:
			"Sunucumuz insanları bir araya getirmeyi ve her zaman kurallara göre sohbet etmeyi amaçlamaktadır, Member Counter sayesinde sunucumuzdaki üye sayısını ve daha birçok istatistiği topluluğumuzla birlikte görüp takip ediyoruz.",
		invite: "https://discord.gg/N5yGQGvBAr",
		inviteText: "Sunucuya katıl"
	},
	{
		name: "Frosty's Ship",
		image:
			"https://media.discordapp.net/attachments/912366555713667072/912366695597887529/FrostyPFP.gif?size=96",
		counters: [
			{
				type: "category",
				name: "❓ DOCK"
			},
			{
				type: "announcement",
				name: "announcements"
			},
			{
				type: "rules",
				name: "rules"
			},
			{
				type: "text",
				name: "server-updates",
				initActive: true
			},
			{
				type: "voice",
				name: "87 Members"
			}
		],
		description:
			"The Community around Frosty as well for gaming & just chilling.\nThey use Member Counter's to keep track of the members in the server",
		invite: "https://discord.gg/gf99YCTAwX"
	}
];

demoServers.forEach((server) => {
	Array(17 - server.counters.length)
		.fill(0)
		.forEach(() => {
			server.counters.push({
				type: "skeleton",
				width: random(40, 90),
				opacity: random(20, 30)
			});
		});
});

const Demo = () => {
	const [showHelp, setShowHelp] = useState(false);
	const [mouseIsHovering, setMouseIsHovering] = useState(false);
	const [selectedServerNumber, setSelectedServerNumber] = useState(0);
	const [selectedChannelNumber, setSelectedChannelNumber] = useState(0);
	const selectedServer = demoServers[selectedServerNumber];
	const selectedChannel = selectedServer.counters[selectedChannelNumber];

	useEffect(() => {
		setSelectedChannelNumber(
			selectedServer.counters.findIndex((counter) => counter.initActive)
		);
	}, [selectedServerNumber, selectedServer]);

	useEffect(() => {
		if (!mouseIsHovering) {
			const serverSelection = setInterval(() => {
				let nextServer = selectedServerNumber + 1;
				if (nextServer === demoServers.length) nextServer = 0;
				setSelectedServerNumber(nextServer);
			}, 7 * 1000);
			return () => {
				clearInterval(serverSelection);
			};
		} else {
		}
	}, [mouseIsHovering, selectedServerNumber]);

	const landscape = ["landscape-primary", "landscape-secondary"].includes(
		window.screen.orientation.type
	);

	return (
		<div className={styles.root}>
			<h1>See how people use Member Counter</h1>
			<div className={styles.demoWrapper}>
				<div className={styles.invalidWidth}>
					{landscape ? <AspectRatioIcon /> : <ScreenRotationIcon />}
					<Typography>
						There is no enough space to show the demo, please{" "}
						{landscape ? "resize the window" : "rotate your device"}
					</Typography>
				</div>
				<div
					className={styles.demo}
					role="none"
					tabIndex="-1"
					onMouseEnter={() => setMouseIsHovering(true)}
					onMouseLeave={() => setMouseIsHovering(false)}
					onFocus={() => setMouseIsHovering(true)}
					onBlur={() => setMouseIsHovering(false)}
				>
					<DSelector
						items={demoServers.map((server, i) => ({
							...server,
							isSelected: i === selectedServerNumber,
							run: () => setSelectedServerNumber(i),
							disableDefaultTint: true
						}))}
						disableBorder
						noScrollBar
					/>
					<div className={styles.serverChannelList}>
						<div className={styles.serverChannelListBar}>
							<Typography variant="h6" component="p">
								{selectedServer.name}
							</Typography>
						</div>
						{selectedServer.counters.map((channel, i) => {
							const Icon = channel && ChannelIcons[channel.type];
							let isOutlined =
								["voice", "category"].includes(channel.type) && showHelp;

							if (channel.type === "category" && isOutlined)
								isOutlined = !!channel.isCounter;

							const selectable = channel.topic;

							return channel.type !== "skeleton" ? (
								<div
									className={classNames(styles.item, {
										[styles.active]: selectedChannelNumber === i,
										[styles.fixTextType]: TextBased.includes(channel.type),
										[styles.outlined]: isOutlined,
										[styles.disabled]: !selectable,
										[styles.category]: channel.type === "category"
									})}
									key={selectedServer.name + i}
									role="menuitem"
									{...(selectable && {
										tabIndex: 0,
										onClick: () => channel.topic && setSelectedChannelNumber(i),
										onKeyPress: () =>
											channel.topic && setSelectedChannelNumber(i)
									})}
								>
									<Typography>
										{Icon} <span>{channel.name}</span>
									</Typography>
								</div>
							) : (
								<div
									className={classNames(styles.item, styles.disabled)}
									key={selectedServer.name + i}
								>
									<div
										className={styles.skeleton}
										style={{
											backgroundColor: `rgba(255, 255, 255, 0.${channel.opacity})`,
											width: `${channel.width}%`
										}}
									/>
								</div>
							);
						})}
					</div>
					<div className={styles.serverChannel}>
						<div className={styles.serverChannelBar}>
							{selectedChannel && selectedChannel.type !== "voice" && (
								<>
									{ChannelIcons[selectedChannel.type]}
									<Typography
										variant="h6"
										component="p"
										className={styles.name}
									>
										{selectedChannel.name}
									</Typography>
									{selectedChannel.topic && (
										<>
											<div className={styles.separator} />
											<Typography
												variant="h6"
												component="p"
												className={classNames(styles.topic, {
													[styles.outlined]:
														TextBased.includes(selectedChannel.type) && showHelp
												})}
											>
												{selectedChannel.topic}
											</Typography>
										</>
									)}
									<div className={styles.grow} />
									<Tooltip title="Highlight counters" placement="top">
										<IconButton onClick={() => setShowHelp(!showHelp)}>
											<HelpOutline />
										</IconButton>
									</Tooltip>
								</>
							)}
						</div>
						<div className={styles.serverChatWrapper}>
							<div className={styles.serverChat}>
								<Typography variant="h5" component="p">
									{selectedServer.name}
								</Typography>
								<Typography component="p">
									{selectedServer.description}
								</Typography>
								{selectedServer.invite && (
									<Button
										color="primary"
										variant="contained"
										fullWidth
										href={selectedServer.invite}
										target="_blank"
										rel="noreferrer"
									>
										{selectedServer.inviteText
											? selectedServer.inviteText
											: "Join server"}
									</Button>
								)}

								{selectedServer.inviteBot && (
									<Button
										color="primary"
										variant="contained"
										fullWidth
										href={selectedServer.inviteBot}
										target="_blank"
										rel="noreferrer"
									>
										{selectedServer.inviteBotText}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Demo;

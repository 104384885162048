import { useEffect } from "react";

const defaultTitle = "Member Counter";

const Page = (props) => {
	useEffect(() => {
		document.title = props.title
			? props.title + ` - ${defaultTitle}`
			: defaultTitle;
		return () => {
			document.title = defaultTitle;
		};
	}, [props.title]);
	return props.children;
};

export default Page;

import React, { useState } from "react";
import styles from "./DSelector.module.css";
import { Tooltip } from "@material-ui/core";
import classNames from "classnames";

const Item = (props) => {
	let {
		image,
		run,
		name,
		isSelected,
		notSelectable,
		disableDefaultTint
	} = props;
	const Icon = image;
	const imageType = typeof image;

	if (notSelectable) isSelected = false;

	const selectedIndicatorStyle = {
		transform: "translate(-20px, 0)"
	};
	const selectedItemStyle = { borderRadius: "15px" };
	const itemImageStyle =
		imageType === "string" ? { backgroundImage: `url('${image}')` } : null;

	return (
		<div className={styles.itemWrapper}>
			<div
				className={styles.selectedItemIndicator}
				style={isSelected ? null : selectedIndicatorStyle}
			/>
			<div
				className={styles.item}
				style={isSelected ? selectedItemStyle : null}
			>
				<Tooltip title={name} placement="right">
					<div
						className={classNames(styles.itemImage, {
							[styles.itemImage]: isSelected,
							[styles.disableDefaultTint]: disableDefaultTint
						})}
						style={itemImageStyle}
						onClick={run}
						onKeyPress={run}
						role="link"
						tabIndex="0"
						aria-label={name}
					>
						{imageType === "undefined" ? (
							<div aria-hidden="true">
								{name
									.split(/\s+/g)
									.map((x) => x[0])
									.join("")}
							</div>
						) : imageType === "object" ? (
							<Icon />
						) : null}
					</div>
				</Tooltip>
			</div>
		</div>
	);
};

const DSelector = (props) => {
	const { items, toggler, disableBorder, noScrollBar } = props;
	const [selectedItem, setSelectedItem] = useState(null);

	return (
		<nav
			className={classNames(styles.main, {
				[styles.mainRightBorder]: !disableBorder,
				[styles.noScrollBar]: noScrollBar
			})}
		>
			{toggler ? (
				<>
					<Item {...toggler} onClick={() => setSelectedItem(null)} />
					<div className={styles.dividerWrapper}>
						<div className={styles.divider} />
					</div>
				</>
			) : null}
			{items.map((item, i) => (
				<Item
					{...item}
					key={i}
					isSelected={item.isSelected ?? i === selectedItem}
					onClick={() => setSelectedItem(i)}
					onKeyPress={() => setSelectedItem(i)}
				/>
			))}
		</nav>
	);
};

export default DSelector;

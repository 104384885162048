import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = () => {
	const { pathname, hash } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
		if (hash) window.location.href = hash;
	}, [pathname, hash]);
	return null;
};

export default ScrollTop;

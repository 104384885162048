import bent from "bent";
import httpStatus from "http-status";

const baseUrl =
	process.env.REACT_APP_API_BASE_URL || window.location.origin + "/v1";

const successCodes = [200, 201, 202, 203, 204, 205, 206];
const headers = {
	get Authorization() {
		return "Bearer " + localStorage.getItem("token");
	}
};

const baseParams = [...successCodes, baseUrl, headers];

const get = bent("GET", "json", ...baseParams);
const post = bent("POST", "json", ...baseParams);
const patch = bent("PATCH", "json", ...baseParams);
const rawGet = bent("GET", ...baseParams);
const rawPost = bent("POST", ...baseParams);
const rawPut = bent("PUT", ...baseParams);
const rawDel = bent("DELETE", ...baseParams);

const handleError = async (responseRequest) => {
	console.log(responseRequest);
	const response = await responseRequest.json();
	if (response.code === httpStatus.UNAUTHORIZED) {
		sessionStorage.setItem("redirectAfterLogin", window.location.pathname);
		client.logout();
		window.location.href = "/login";
		return;
	}
	throw new Error(response.message);
};

const client = {
	get isLoggedIn() {
		return !!localStorage.getItem("token");
	},
	// Auth
	getOAuth2Screen: async () => {
		const { url } = await get(`/auth`);
		return url;
	},
	login: async (codeGrant) => {
		const { accessToken, refreshToken } = await get(
			`/auth/callback?code=${codeGrant}`
		).catch(handleError);
		localStorage.setItem("token", accessToken);
		localStorage.setItem("refresh", refreshToken);
	},
	refreshTokens: async () => {
		if (!localStorage.getItem("refresh")) return;
		const { accessToken, refreshToken } = await get(
			`/auth/refresh?refreshToken=${localStorage.getItem("refresh")}`
		).catch(handleError);
		localStorage.setItem("token", accessToken);
		localStorage.setItem("refresh", refreshToken);
	},
	logout: () => {
		if (!localStorage.getItem("token")) return;
		localStorage.removeItem("token");
		localStorage.removeItem("refresh");
	},

	// User
	getMe: () => get("/users/@me").catch(handleError),
	getMyDiscord: () => get("/users/@me/discord").catch(handleError),
	getMyBots: () => get("/users/@me/bots").catch(handleError),
	getMyGuilds: () => get("/users/@me/guilds").catch(handleError),
	getMyInvoices: ({
		sortBy = "new",
		page = 1,
		limit = 0,
		quarter,
		year,
		revised = false
	}) => {
		const params = new URLSearchParams();

		sortBy && params.set("sortBy", sortBy);
		page && params.set("page", page);
		limit && params.set("limit", limit);
		quarter && params.set("quarter", quarter);
		year && params.set("year", year);
		params.set("revised", revised);

		return get(`/users/@me/invoices?${params}`).catch(handleError);
	},
	getUser: (id) => get(`/users/${id}`).catch(handleError),
	modifyUser: (id, user) => patch(`/users/${id}`, user).catch(handleError),
	deleteUser: (id) => rawDel(`/users/${id}`).catch(handleError),
	getUserBots: (id) => get(`/users/${id}/bots`).catch(handleError),
	getUserInvoices: ({
		id,
		sortBy = "new",
		page = 1,
		limit = 0,
		quarter,
		year,
		revised = false
	}) => {
		const params = new URLSearchParams();

		sortBy && params.set("sortBy", sortBy);
		page && params.set("page", page);
		limit && params.set("limit", limit);
		quarter && params.set("quarter", quarter);
		year && params.set("year", year);
		params.set("revised", revised);

		return get(`/users/${id}/invoices?${params}`).catch(handleError);
	},

	// Bots
	createBot: () => post(`/bots`).catch(handleError),
	getBot: (id) => get(`/bots/${id}`).catch(handleError),
	modifyBot: (id, bot) => patch(`/bots/${id}`, bot).catch(handleError),
	deleteBot: (id) => rawDel(`/bots/${id}`).catch(handleError),
	getBotDiscordProfile: (id) => get(`/bots/${id}/discord`).catch(handleError),
	getBotStatus: (id) => get(`/bots/${id}/status`).catch(handleError),
	startBot: (id) => rawPut(`/bots/${id}/start`).catch(handleError),
	stopBot: (id) => rawPut(`/bots/${id}/stop`).catch(handleError),
	restartBot: (id) => rawPut(`/bots/${id}/restart`).catch(handleError),
	setBotSubscription: (id, subscriptionId, quota) => {
		const params = new URLSearchParams();

		params.set("id", subscriptionId);
		quota && params.set("quota", quota);

		return rawPost(`/bots/${id}/subscription?${params}`).catch(handleError);
	},
	getBotSubscription: (id) =>
		get(`/bots/${id}/subscription`).catch(handleError),
	pauseBotSubscription: (id) =>
		rawPut(`/bots/${id}/subscription/pause`).catch(handleError),
	resumeBotSubscription: (id) =>
		rawPut(`/bots/${id}/subscription/resume`).catch(handleError),
	deleteBotSubscription: (id) =>
		rawDel(`/bots/${id}/subscription`).catch(handleError),

	// invoices
	getAllInvoices: ({
		sortBy = "new",
		page = 1,
		limit = 0,
		quarter,
		year,
		revised = false
	}) => {
		const params = new URLSearchParams();

		sortBy && params.set("sortBy", sortBy);
		page && params.set("page", page);
		limit && params.set("limit", limit);
		quarter && params.set("quarter", quarter);
		year && params.set("year", year);
		params.set("revised", revised);

		return get(`/invoices?${params}`).catch(handleError);
	},
	getInvoice: (id, revised = false) =>
		get(`/invoices/${id}?revised=${revised}`).catch(handleError),
	getInvoiceAsPDF: (id, revised = false) =>
		rawGet(`/invoices/${id}/pdf?revised=${revised}`).catch(handleError),
	getInvoicesAsOSS: ({ quarter, year }) => {
		const params = new URLSearchParams();

		quarter && params.set("quarter", quarter);
		year && params.set("year", year);

		return rawGet(`/invoices/oss?${params}`).catch(handleError);
	},

	// MOTD
	getMotd: () => get(`/motd`).catch(handleError),
	postMotd: (motd) => rawPost(`/motd`, { motd }).catch(handleError)
};

// refresh tokens every 15min
setInterval(() => client.refreshTokens().catch(console.error), 1000 * 60 * 15);

export default client;

import React from "react";
import { ExternalLink, InternalLink } from "./common/Link";
import styles from "./Footer.module.css";

const Footer = () => {
	return (
		<footer className={styles.root}>
			<div className={styles.linkSection}>
				<div>
					<h3>Links</h3>
					<ExternalLink href={process.env.REACT_APP_SUPPORT_SERVER}>
						Support server
					</ExternalLink>

					<ExternalLink href={"https://docs.member-counter.eduardozgz.com/"}>
						Bot documentation
					</ExternalLink>

					<ExternalLink
						href={"https://github.com/eduardozgz/member-counter-bot"}
					>
						GitHub
					</ExternalLink>
					<InternalLink to="/login">Login with Discord</InternalLink>
				</div>
				<div>
					<h3>Legal</h3>
					<InternalLink to="/legal/terms-of-service">
						Terms Of Service
					</InternalLink>
					<InternalLink to="/legal/privacy-policy">Privacy Policy</InternalLink>
					<InternalLink to="/legal/cookie-policy">Cookie Policy</InternalLink>
					<InternalLink to="/legal/acceptable-use-policy">
						Acceptable Use Policy
					</InternalLink>
				</div>
			</div>
			<div className={styles.denseFooter}>
				© 2021 Member Counter. All rights reserved. Created by{" "}
				<ExternalLink href="https://github.com/eduardozgz">
					eduardozgz
				</ExternalLink>
			</div>
		</footer>
	);
};

export default Footer;
